import { Component, Vue, Mixins } from 'vue-property-decorator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import template from './Reporting.Template.vue';
import { ApiService } from '@/services/base/ApiService';
import { ApiHelper } from '@/services/ApiHelper';
import { CriteriaBase, FileContentResult } from '@/shared/models';

@Component({
    ...template,
    name: 'Reporting',
})
export default class Reporting extends Mixins(GrilleMixin) {
    public loading: boolean = false;
    public criteria: CriteriaBase = new CriteriaBase();
    public totalDemandesUtilisateurs: number = 0;

    public dernierExportBase: { nom: string, dateCreation: string } = null;

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((
                title: string | null,
                message: string | null,
                options: { color?: string; width?: number; zIndex?: number }
            ) => Promise<boolean>);
        };
    };

    /**
     * Crée une instance de Reporting.
     * @memberof Reporting
     */
    constructor() {
        super();
    }

    public created() {
        this.dernierExportBaseAuto();
    }

    /**
     * Récupération des informations du dernier export généré automatiquement.
     */
    public dernierExportBaseAuto(): void {
        const apiExport = new ApiService<any>(`/reporting/RecupererInformationsDernierExportAuto`);
        apiExport.getWhereSingle(this.criteria.queryString)
            .then((reponse: any) => {
                this.dernierExportBase = reponse.data as { nom: string, dateCreation: string };
            });
    }
    /**
     * Export de base quotidien au format Excel.
     * @memberof Reporting
     */
    public exportBaseQuotidien(): void {
        const apiExport = new ApiService<FileContentResult>(`/reporting/ExportBaseQuotidienExcel`);
        this.exportBase(apiExport);
    }

    /**
     * Export de base intégral au format Excel.
     * @memberof Reporting
     */
    public exportBaseIntegral(): void {
        const apiExport = new ApiService<FileContentResult>(`/reporting/ExportBaseIntegralExcel`);
        this.exportBase(apiExport);
    }

    /**
     * Dernier export auto.
     * @memberof Reporting
     */
    public exportDernierExportAuto(): void {
        const apiExport = new ApiService<FileContentResult>(`/reporting/TelechargerDernierExportAuto`);
        this.exportBase(apiExport);
    }

    /**
     * Export de base.
     * @param api
     * @memberof Reporting
     */
    private exportBase(api: ApiService<FileContentResult>): void {
        this.loading = true;
        api
            .getWhereSingle(this.criteria.queryString)
            .then((reponse) => {
                ApiHelper.createAndDownloadBlobFile(reponse.data);
            })
            .finally(() => (this.loading = false));
    }

}
